<template>
  <div id="app">
    <GlobalHeader />
    <GlobalPolicy />
    <div class="router-page">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" class="container" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" class="container" />
    </div>

    <GlobalFooter />
  </div>
</template>
<script>
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'
import GlobalPolicy from '@/components/GlobalPolicy'
export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter,
    GlobalPolicy,
  },
  created() {
    const _this = this
    _this.$store.dispatch('resize')
    window.addEventListener('resize', function () {
      _this.$store.dispatch('resize')
    })
  },
}
</script>
