import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Home',
      keepAlive: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView'),
  },
  {
    path: '/team',
    name: 'team',
    meta: {
      title: 'Team',
    },
    component: () => import(/* webpackChunkName: "team" */ '@/views/TeamView'),
  },
  {
    path: '/folder',
    name: 'folder',
    meta: {
      title: 'Folder',
    },
    component: () => import(/* webpackChunkName: "folder" */ '@/views/FolderView'),
  },
  {
    path: '/courses',
    name: 'courses',
    meta: {
      title: 'Course & Fee',
    },
    component: () => import(/* webpackChunkName: "course" */ '@/views/CourseFee'),
  },
  {
    path: '/studio-rental',
    name: 'studio-rental',
    meta: {
      title: 'Studio Rental',
    },
    component: () => import(/* webpackChunkName: "studio-rental" */ '@/views/StudioRental'),
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: 'Contact',
    },
    component: () => import(/* webpackChunkName: "contact" */ '@/views/ContactView'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: {
      title: 'Privacy Policy',
    },
    component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/PrivacyPolicy'),
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    meta: {
      title: 'Terms Conditions',
    },
    component: () => import(/* webpackChunkName: "terms-conditions" */ '@/views/TermsConditions'),
  },
  {
    path: '/video-archive',
    name: 'video-archive',
    meta: {
      title: 'Video Archive',
    },
    component: () => import(/* webpackChunkName: "terms-conditions" */ '@/views/VideoView'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 }),
})

router.beforeEach((to, form, next) => {
  document.title = to.meta.title + ' - BGM'
  next()
})
export default router
