<template>
  <div class="global-footer">
    <div class="footer-border" />
    <div class="footer-content">
      <ul class="menu">
        <li v-for="(item, index) in menuList" :key="index" class="menu-item pointer">
          <b @click="handleJump(item)">{{ item }}</b>
        </li>
      </ul>
      <div v-if="$store.state.isDesktop" class="footer-info">
        <div class="info-top">
          <div class="qr-code">
            <SvgIcon name="qr-code" />
          </div>
          <div class="scan-info">
            <img src="@/assets/images/scan-info.png" alt="" />
          </div>
          <div class="bgm-vertical">
            <SvgIcon name="bgm-vertical" />
          </div>
        </div>
        <div class="info-bottom">
          <div class="follow-us">
            <SvgIcon name="follow-us" />
            <div class="media">
              <SvgIcon class="pointer" name="ins-icon" link="https://instagram.com/bgmdancestudio?igshid=YmMyMTA2M2Y=" />
              <SvgIcon
                class="pointer"
                name="xhs-icon"
                link="https://www.xiaohongshu.com/user/profile/5f62eda20000000001002c48?xhsshare=CopyLink&appuid=5c6ec3a00000000010033bb0&apptime=1657845512"
              />
              <SvgIcon class="pointer" name="youtube-icon" link="https://youtube.com/c/BGMDanceStudioVancouver" />
              <SvgIcon class="pointer" name="facebook-icon" link="https://m.facebook.com/bgmdancestudio/" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="footer-info">
        <div class="scan-info">
          <img src="@/assets/images/scan-info-mobile.png" alt="" />
          <div class="media">
            <SvgIcon class="pointer" name="ins-icon" link="https://instagram.com/bgmdancestudio?igshid=YmMyMTA2M2Y=" />
            <SvgIcon
              class="pointer"
              name="xhs-icon"
              link="https://www.xiaohongshu.com/user/profile/5f62eda20000000001002c48?xhsshare=CopyLink&appuid=5c6ec3a00000000010033bb0&apptime=1657845512"
            />
            <SvgIcon class="pointer" name="youtube-icon" link="https://instagram.com/bgmdancestudio?igshid=YmMyMTA2M2Y=" />
            <SvgIcon class="pointer" name="facebook-icon" link="https://m.facebook.com/bgmdancestudio/" />
          </div>
        </div>
        <div class="url-info">
          <b>WWW. BGMDANCESTUDIO.COM</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalFooter',
  data() {
    return {
      menuList: ['HOME', 'FACULTY', 'COURSES', 'STUDIO RENTAL', 'VIDEO ARCHIVE', 'CONTACT', 'PRIVACY POLICY', 'TERMS & CONDITIONS'],
    }
  },
  methods: {
    handleJump(menu) {
      const route = '/' + menu.toLowerCase().replace('home', '').replace(' & ', '-').replace(' ', '-')
      if (this.$route.path === route) return false
      this.$router.push(route)
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
