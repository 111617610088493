import Vue from 'vue'

export default class Fadein {
  constructor() {
    this.list = []
  }

  add(el, binding) {
    const _this = this
    Vue.nextTick(() => {
      if (binding.value && binding.value.bind == true) {
        el.classList.add('fadein')
        if (binding.value && binding.value.vertical) {
          el.classList.add('vertical')
        }
        _this.list.push({ el, binding })
        window.addEventListener('scroll', _this.handleScroll.bind(this))
      }
    })
  }

  handleScroll() {
    const _this = this
    _this.list.forEach(async (item) => {
      if (_this.isInViewPort(item.el)) {
        if (item.binding.value) {
          setTimeout(function () {
            item.el.classList.add('show')
          }, item.binding.value.delay)
        } else {
          item.el.classList.add('show')
        }
        await new Promise((resolve) => {
          setTimeout(function () {
            resolve()
          }, 100)
        })
      }
    })
  }

  isInViewPort(el) {
    const transformTop = window
      .getComputedStyle(el)
      .transform.replace(/[^0-9\-,]/g, '')
      .split(',')
      .pop()
    const { top, height } = el.getBoundingClientRect()
    const { innerHeight } = window
    return innerHeight - (top - transformTop) + transformTop - height / 2 > 0
  }

  destroy() {
    window.removeEventListener('scroll', this.handleScroll)
    this.list = []
  }
}
