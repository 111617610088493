<template>
  <div class="wrap">
    <div class="global-header">
      <div class="pointer" @click="handleJump('home')">
        <SvgIcon name="logo" />
      </div>
      <div v-if="$store.state.isDesktop" class="menu pointer">
        <a class="btn" href="https://clients.mindbodyonline.com/classic/ws?studioid=5735401&stype=-2&subTab=info" target="_blank">BOOK</a>
        <SvgIcon v-if="isOpen" name="menu" @click.native="isOpen = !isOpen" />
        <SvgIcon v-else name="close" @click.native="isOpen = !isOpen" />
      </div>
      <div v-else class="menu pointer">
        <a class="btn" href="https://clients.mindbodyonline.com/classic/ws?studioid=5735401&stype=-2&subTab=info" target="_blank">BOOK</a>
        <SvgIcon name="hamburger" @click.native="isOpen = !isOpen" />
      </div>
    </div>
    <div :class="{ 'menu-collapse': true, 'active': !isOpen }">
      <div class="menu-content">
        <div v-if="!$store.state.isDesktop" class="menu-header">
          <div @click="isOpen = !isOpen"><SvgIcon name="close-icon" /></div>
        </div>
        <div class="menu-wrap">
          <div class="menu-left">
            <div v-for="(item, index) in menuList" :key="index" class="menu-item">
              <b class="pointer" @click="handleJump(item)">{{ item }}</b>
            </div>
          </div>
          <div class="menu-right">
            <!-- <img src="@/assets/images/liquid-gradient-bg.png" alt="" /> -->
            <video
              muted
              autoplay
              loop
              playsinline
              webkit-playsinline
              src="https://player.vimeo.com/progressive_redirect/playback/732599645/rendition/1080p/file.mp4?loc=external&signature=f22d41743d0ff4dddf20594065bbf8bacc6b85fa58da27824526200b599df3a0"
            />
          </div>
        </div>
        <div class="menu-footer">
          <div v-if="$store.state.isDesktop" class="footer-logo">
            <SvgIcon name="menu-footer" />
          </div>
          <div class="footer-content">
            <SvgIcon name="bgm-dance-studio" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalHeader',
  data() {
    return { isOpen: true, menuList: ['HOME', 'FACULTY', 'COURSES', 'STUDIO RENTAL', 'VIDEO ARCHIVE', 'CONTACT'] }
  },
  methods: {
    handleJump(menu) {
      const route = '/' + menu.toLowerCase().replace('home', '').replace(' & ', '-').replace(' ', '-')
      this.isOpen = true
      if (this.$route.path === route) return false
      this.$router.push(route)
    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
