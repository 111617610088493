<template>
  <div class="cover">
    <div class="policy-collapse">
      <div class="collapse-btn pointer" @click="isCollapse = !isCollapse">
        <b class="btn-title"
          >COVID-19 <br />
          POLICY</b
        >
      </div>
      <div :class="{ 'content-wrap': true, 'active': isCollapse }">
        <div class="content">
          <div class="logo-wrap">
            COVID-19<br />
            VANCOUVER
          </div>
          <b class="policy-title"
            >The health and safety of all students is our priority at BGM and as such we are committed to following the safety measures laid out by both
            provincial health officers and the Ministry of Health. As of September 13th, 2021, by order of the BC Provincial Health Officer, proof of
            vaccination against COVID-19 for those aged 12+ is required to access certain events, services, and businesses including restaurants, gyms, and
            dance studios. BGM Dance studio is required to follow this public health order and we will request proof of vaccination for all those choosing to
            attend our in-person classes.</b
          >
          <b class="policy-desc">
            We are required to check two documents when you visit the studio:
            <br />
            <br />
            1. Proof of vaccination - Your BC vaccine card (digital or paper copy).
            <br />
            <br />
            2. Valid government ID (only required for those 19+).
            <br />
            <br />
            We will also perform a temperature check at the door before entry as an added precaution.
            <br />
            <br />
            <br />
            For more information on the recent public health order, please visit https://www2.gov.bc.ca/vaccinecard.html.
            <br />
            <br />
            To ensure the health and safety of our community, the following policies will be implemented at the studio:
            <br />
            <br />
            Face coverings are optional. However, we highly recommend that all students wear a mask.
            <br />
            <br />
            All students must pre-register for classes online, as we are not accepting cash payments at this time. Check-in for every class closes 5-minutes
            before that class' start time.
            <br />
            <br />
            Hand sanitizing stations are provided inside the studio and at the front desk. We also have one hand-washing facility on site.
            <br />
            <br />
            At least one staff member will always be present during studio business hours and will actively sanitize all surfaces hourly. In between each class,
            there is a break to allow for a crossover where this process will take place. Depending on the number of classes held, this will occur 4-5 times
            daily. Staff are provided with commercial-grade disinfectant and cleaning supplies and have been trained in proper cleaning and disinfecting
            techniques as well. They have also been instructed to wash their hands at the end of each cleaning break.
            <br />
            <br />
            Thank you for your continued support and understanding during this time. We will continue to update you all as more information becomes available to
            us.
            <br />
          </b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlobalPolicy',
  data() {
    return {
      isCollapse: false,
    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
