<template>
  <svg :class="`svg-icon icon-${name}`" aria-hidden="true" @click="onIconClicked">
    <use :xlink:href="`#icon-${name}`" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    link: {
      type: String,
      required: false,
    },
  },
  methods: {
    onIconClicked() {
      if (this.link) {
        window.open(this.link)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.svg-icon {
  display: block;
}
</style>
